<template>
    <div class="experimental-facilities">
        <div class="intro-full-w bg-img">
            <intro-page :pageId="currentPageId"></intro-page>
        </div>
        <div class="two-column-layout section dark-blue">
        <div class="container">
        <div class="row mt-5">
            <div class="col-lg-6 order-lg-1 order-md-2 text">
            <h6 class="bullet-list-deco">
                EXPERIMENTAL FACILITIES
            </h6>
            <h3 class="light">
                {{this.pageData.italy_titolo}}
            </h3>
            <p v-html="this.pageData.italy_testo"></p>
            </div>
            <div class="col-lg-6 order-lg-2 order-md-1 image">
            </div>
        </div>
        </div>
    </div>
    <div class="bg-full d-none d-md-block">
        <div class="container">
            <img src="../assets/experimental-facilities2.jpg" alt="">
        </div>
    </div>
    </div>
</template>
<script>

import IntroPage from '../components/IntroPage.vue'
export default {
    name:"experimental-facilities",
    components: {
        IntroPage
    },
    data:()=>{
        return {
            pageData:"",
            currentPageId:"219d6b80-e5a0-4991-84a7-883a2c857a3d",
        }
    },
    methods:{
    fetchPageData(){
        fetch(`${process.env.VUE_APP_ENDPOINT}/custom-texts/experimental`)
        .then((response) => {
          return response.json();
        })
        .then((json) => {

          this.pageData = json.data.italy;
        })
        .catch(function (error) {
          
          
          
          ("Request failed", error);
        });
      },
    },
   mounted(){
       this.fetchPageData()
   }
};

</script>
<style lang="scss" scoped>
 .experimental-facilities .bg-img {
        background-position: center right, bottom left ;
        background-size: contain,80%;
        background-image: url("../assets/experimental-facilities-intro.png"),url("../assets/filigrana-experimental-facilities.svg") ;
    }
  .experimental-facilities .two-column-layout {
    .image{
      background-image: url("../assets/experimental-facilities.jpg") ;
    }
}
.bg-full {
    margin-bottom: 8rem;
    position: relative;
    &:before {
      content: "";
      display: block;
      width: 100%;
      height: 450px;
      background: #293446;
      position: absolute;
      z-index: -1;
    }
    img {
        margin-top: 150px;
        width: 100%;
    }
}
h3 {
    margin: 2rem 0rem;
}
@media (max-width:992px) {
 .experimental-facilities .bg-img {
        background-image:url("../assets/filigrana-mobile.svg") ;
        background-size: 30%;
        background-position:top right ;
    }
}
</style>